import React, { Suspense, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import useWidth from "../hooks/useWidth";
import useSidebar from "../hooks/useSidebar";
import useMenuLayout from "../hooks/useMenuLayout";
import useMenuHidden from "../hooks/useMenuHidden";
import { motion } from "framer-motion";
import MobileMenu from "../components/MobileMenu";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { useLocation, useNavigate } from "react-router-dom";

export default function AuthorizedLayout({ language, setLanguage, children }) {
  const { width, breakpoints } = useWidth();
  const [collapsed] = useSidebar();

  const switchHeaderClass = () => {
    if (menuType === "horizontal" || menuHidden) {
      return "ml-0";
    } else if (collapsed) {
      return "ml-[72px]";
    } else {
      return "ml-[300px]";
    }
  };
  // content width
  const [menuType] = useMenuLayout();
  const [menuHidden] = useMenuHidden();

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const location = useLocation();

  useEffect(() => {
    if (!user.loading && !user.token) {
      // If the user has not created an account yet, then we send them to the create account form
      navigate("/login");
    }
  }, [user]);

  // mobile menu
  return (
    <>
      {/* TODO: add mobile header with back,logo and language  */}
      {/* <Header className={width > breakpoints.xl ? switchHeaderClass() : ''} /> */}
      {menuType === "vertical" && width > breakpoints.xl && !menuHidden && (
        <Sidebar language={language} setLanguage={setLanguage} />
      )}
      {width < breakpoints.lg &&
        (location.pathname === "/" ||
          location.pathname === "/lifestyle" ||
          location.pathname.includes("/profile")) && (
          <MobileMenu path={location.pathname} />
        )}
      <div
        className={`content-wrapper transition-all duration-150 ${
          width > 1280 ? switchHeaderClass() : ""
        }`}
      >
        {/* md:min-h-screen will h-full*/}
        <div className="page-content page-min-height">
          <div className={"w-full"}>
            {/* TODO: ADD LOADING ANIMATION */}
            <Suspense fallback={<></>}>
              <motion.div
                key={window.location.pathname}
                initial="pageInitial"
                animate="pageAnimate"
                exit="pageExit"
                variants={{
                  pageInitial: {
                    opacity: 0,
                    y: 50,
                  },
                  pageAnimate: {
                    opacity: 1,
                    y: 0,
                  },
                  pageExit: {
                    opacity: 0,
                    y: -50,
                  },
                }}
                transition={{
                  type: "tween",
                  ease: "easeInOut",
                  duration: 0.5,
                }}
                className=""
              >
                {children}
              </motion.div>
            </Suspense>
          </div>
        </div>
      </div>
      {/* {width < breakpoints.md && <MobileFooter />} */}
    </>
  );
}
