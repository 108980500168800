import React from "react";
import DateInput from "./DateInput";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";

export default function ProfileInfoForm({
  formData,
  setFormData,
  saveProfile,
  citySearch,
  setCitySearch,
  filteredCities,
  setFilteredCities,
  changeCitySearch,
}) {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  return (
    <div className="pt-12 flex flex-col items-center">
      <div className="w-[90%]">
        <h2 className="lg:text-[32px] max-lg:text-[26px] text-white">
          Jouw profiel
        </h2>
        <div className="mt-[16px]">
          <h3 className="text-[18px] text-white">Voor- en achternaam</h3>
          <div className="mt-1">
            <input
              type="text"
              name="name"
              className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border !border-gray-600 !rounded-b-none !bg-transparent"
              id="validationCustom01"
              placeholder="Voornaam"
              value={formData.first_name}
              onChange={(e) => {
                setFormData({ ...formData, first_name: e.target.value });
              }}
            />
            <input
              type="text"
              name="name"
              className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border !border-gray-600 !rounded-t-none !border-t-0 !bg-transparent"
              id="validationCustom01"
              placeholder="Achternaam"
              value={formData.last_name}
              onChange={(e) => {
                setFormData({ ...formData, last_name: e.target.value });
              }}
            />
          </div>
          <div className="mt-[16px]">
            <h3 className="text-[18px] text-white">Geboortedatum</h3>
            <div className="mt-1">
              <DateInput
                className="w-full text-[16px] !py-[15px] outline-none !text-neutral-200 !border-[1px] border-gray-600 rounded-[4px] !bg-transparent"
                dob={formData.date_of_birth}
                setDob={(d) => {
                  setFormData({ ...formData, date_of_birth: d });
                }}
              />
            </div>
          </div>

          <div className="mt-[16px]">
            <h3 className="text-[18px] text-white">Gender</h3>
            <div className="mt-1">
              <select
                value={formData.gender}
                onChange={(e) => {
                  setFormData({ ...formData, gender: e.target.value });
                }}
                className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border rounded-[4px] border-gray-600 w-full !bg-transparent"
              >
                <option value="Man">Man</option>
                <option value="Vrouw">Vrouw</option>
                <option value="Anders">Anders</option>
              </select>
            </div>
          </div>
          <div className="mt-[16px]">
            <h3 className="text-[18px] text-white">City</h3>
            <div className="relative">
              <input
                type="text"
                value={citySearch}
                onChange={changeCitySearch}
                placeholder={t("search_city")}
                className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border rounded-[4px] border-gray-600 w-full !bg-transparent"
                autoComplete="off"
              />
              {citySearch && filteredCities.length > 0 && (
                <ul className="absolute z-10 w-full bg-[#130826] border border-gray-700 rounded-b-[4px] max-h-[200px] overflow-y-auto">
                  {filteredCities.map((city, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 cursor-pointer text-white"
                      onClick={() => {
                        setFilteredCities([]);
                        setFormData({ ...formData, location: city });
                        setCitySearch(city);
                      }}
                    >
                      {city}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="mt-[16px]">
            <h3 className="text-[18px] text-white">E-mail</h3>
            <div className="mt-1">
              <input
                type="email"
                disabled={true}
                name="name"
                className="text-[16px] !py-[15px] outline-none !text-neutral-200 !border !border-gray-600 !bg-transparent"
                id="validationCustom01"
                placeholder="E-mail"
                value={user.email}
                onChange={(e) => {
                  setFormData({ ...formData, last_name: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
