import { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/nl";
import { subscribeEventAPI } from "../utils/config";
import { adjustTime, getPublicImageUrl } from "../helpers/helpers";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import NotificationIcon from "../assets/new-image/Notification.svg";
import NewsLetterImg from "../assets/image svg/newsletter.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { supabase } from "../supabaseClient";

dayjs.extend(utc);
dayjs.extend(timezone);

const Carousel = ({ onlineEvents, token, formattedCurrentDate }) => {
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [subscribeObj, setSubscribeObj] = useState({
    Email: "",
    event: "",
    event_id: "",
    token: "",
  });

  const [userToken, setUserToken] = useState("");

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user.email) {
      setSubscribeObj({
        ...subscribeObj,
        Email: user.email,
      });
    }
  }, [user]);

  useEffect(() => {
    const tokenFromCookie = localStorage.getItem("token");
    setUserToken(tokenFromCookie);
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    const closeOnOutsideClick = (e) => {
      if (!e.target.closest(".modal-content")) {
        closeModalHandler();
      }
    };
    document.addEventListener("click", closeOnOutsideClick);
    return () => {
      document.removeEventListener("click", closeOnOutsideClick);
    };
  }, []);

  if (onlineEvents.length < 1) {
    return null;
  }

  async function sendEmail(userObj) {
    try {
      console.log("userObj", userObj);
      const response = await fetch(`${subscribeEventAPI}`, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userObj),
      });

      return response;
    } catch (err) {
      console.log("Error sending email:", err);
      return {
        status: 500,
        error: err.message || "Failed to send email",
      };
    }
  }

  const sortedByDate = onlineEvents
    .filter((event) => {
      const registerFrom = adjustTime(event.signup_from);

      return formattedCurrentDate < registerFrom;
    })
    .map((event) => {
      const dateObject = dayjs(event.datetime).locale("nl");
      return {
        ...event,
        fulldate: {
          date: dateObject.format("D MMM"),
          time: dateObject.format("HH:mm"),
        },
        // timestamp,
      };
    });

  const getEventData = (eventItem) => {
    const eventName = eventItem.name;
    const eventId = eventItem.id;
    setSubscribeObj((prevState) => ({
      ...prevState,
      event: eventName,
      event_id: eventId,
      token: userToken,
    }));
  };

  const openFirstModal = (e, item) => {
    e.stopPropagation();
    // if (e.target.closest('.swiper-button-next') || e.target.closest('.swiper-button-prev')) return
    getEventData(item);
    setFirstModal(true);
  };

  const validateEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(subscribeObj.Email);
    if (isValidEmail) {
      // setTimeout(() => {
      // const response = await sendEmail(subscribeObj);
      // const response = await sendEmail(updatedSubscribeObj)
      if (user.user_info_id) {
        // Create a TicketAlert record in supabase
        const { data, error } = await supabase.from("TicketAlert").insert({
          event_id: subscribeObj.event_id,
          user_id: user.user_info_id,
        });

        if (error) {
          console.error("Error creating TicketAlert record:", error);
        } else {
          console.log("TicketAlert record created successfully:", data);
        }
        setEmailError(false);
        closeModalHandler();
        setSecondModal(true);
      } else {
        console.log("user.user_info_id", user.user_info_id, user);
      }
    } else {
      setEmailError(true);
    }
  };

  const closeModalHandler = () => {
    setFirstModal(false);
    setEmailError(false);
  };

  return (
    <div
      className={
        onlineEvents.length > 2
          ? "custom-container slides-group"
          : "custom-container "
      }
    >
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        breakpoints={{
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2.6,
          },
        }}
        modules={[Navigation, Scrollbar, A11y]}
        // slidesPerView={'2.5'}
        navigation={onlineEvents.length > 2 ? true : false}
        pagination={{
          clickable: true,
        }}
        scrollbar={{ draggable: true }}
        isDuplicate={onlineEvents.length > 2 ? true : false}
        // onSlideChange={(swiper) => disableSwiperBtns(swiper.activeIndex)}
        className="custom-arrow"
        // spaceBetween={10}
      >
        {sortedByDate.map((item, index) => (
          <SwiperSlide key={index} className="swiper-slide-events">
            <div className="slider-item m-3">
              {/* <img src={item.EvenementImages} alt="Image 1" /> */}
              <img
                src={getPublicImageUrl(item.event_image_filename)}
                alt="Event image"
                className="slider-event-img !rounded-[8px]"
              />
              <button
                className="openFirstModal btn trigger"
                onClick={(e) => openFirstModal(e, item)}
              >
                <img
                  src={NotificationIcon}
                  alt="notification"
                  className="Notification-img"
                />
              </button>
              <div className="section-text">
                <h5 className="lg:text-[18px] lg:leading-[24px] text-[16px] leading-[22px] text-white font-[700]">
                  {/* Zomer w/ Colyn 10HRS */}
                  {/* {item.EvenName} */}
                  {item.name.charAt(0).toUpperCase() +
                    item.name.slice(1).toLowerCase().substring(0, 24)}
                  {item.name.length > 24 ? "..." : ""}
                </h5>
                <h6 className="!text-white lg:text-[16px] text-[14px] font-normal leading-[16px]">
                  {item.venue.name} • {item.city}
                </h6>
                <div className="flex items-center justify-start gap-2 mt-[14px]">
                  <div className="!rounded-[4px] text-white bg-green-600">
                    <span className="!text-[14px] !px-[6px] !py-[4px] !bg-transparent min-w-[55px] flex items-center justify-center">
                      €{parseFloat(item.our_price).toFixed(2)}
                    </span>
                  </div>
                  <div className="!rounded-[4px] text-white bg-gray-600">
                    <span className="line-through !text-[14px] !px-[6px] !py-[4px] !bg-transparent min-w-[55px] flex items-center justify-center">
                      €{parseFloat(item.original_price).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* First Modal */}
      <div className={clsx("modal firstModal", firstModal ? "open" : "")} id="">
        <div className="modal-content max-lg:max-h-[170px] max-lg:!top-[calc(100%-240px)]">
          <div className="header">
            <span
              className="closeSecondModal close-button"
              onClick={closeModalHandler}
            >
              &times;
            </span>
            <h2>{t("keep_me_updated")}</h2>
          </div>
          <div className="Modal-Body">
            {!user.email && (
              <>
                <p>{t("email-address")}</p>
                <input
                  value={subscribeObj.Email}
                  onChange={(e) =>
                    setSubscribeObj({ ...subscribeObj, Email: e.target.value })
                  }
                  id="modalEmailInput"
                  type="email"
                  name="email"
                  // value={modalEmailValue}
                  placeholder="E-mail"
                />
                {emailError ? (
                  <p className="modal-error">{t("invalid_email")}</p>
                ) : (
                  <p className="sturen">{t("email_send_once_event_is_live")}</p>
                )}
              </>
            )}
            {/* <p className="sturen">
              We sturen jou een e-mail zodra het event live is!
            </p> */}
            <button
              className="openSecondModal Stuur"
              type="button"
              onClick={(e) => validateEmail()}
            >
              {t("keep_me_updated")}
            </button>
          </div>
        </div>
      </div>
      {/* Second Modal */}
      <div
        className={clsx("modal secondModal", secondModal ? "open" : "")}
        id=""
      >
        <div className="modal-content max-lg:!top-[calc(100%-470px)]">
          <div className="header">
            <span
              className="closeSecondModal close-button"
              onClick={() => setSecondModal(false)}
            >
              &times;
            </span>
            <h2>Hou me op de hoogte</h2>
          </div>
          <div className="Modal-Body">
            <div className="thnaks-img flex justify-center">
              <img src={NewsLetterImg} alt="newletter" className="thanks" />
            </div>
            <p className="gelukt !text-center">{t("all_good")}</p>
            <button
              className="openSecondModal Stuur"
              type="submit"
              onClick={() => setSecondModal(false)}
            >
              Terug naar home
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Carousel;
