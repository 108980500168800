import React from "react";
import AppleLogo from "../assets/apple-logo.png";

import { supabase } from "../supabaseClient";
import { baseUrl } from "../helpers/helpers";
import { useTranslation } from "react-i18next";

export default function AppleAuth({ redirectUrl }) {
  const appleLogin = () => {
    supabase.auth.signInWithOAuth({
      provider: "apple",
      options: {
        redirectTo:
          `${baseUrl}/create-account?provider=apple` +
          (redirectUrl ? `&redirectUrl=${redirectUrl}` : ""),
      },
    });
  };

  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={() => {
        appleLogin();
      }}
      className="rounded-[8px] text-white text-[16px] font-semibold gap-3 flex items-center w-full bg-transparent justify-center p-[14px] border-2 border-[#B9B6BF]"
    >
      <img src={AppleLogo} className="object-cover h-9 w-auto" />
      {t("continue_with")} Apple
    </button>
  );
}
