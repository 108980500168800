import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../pages/home/Home";
import { supabase } from "../supabaseClient";
import Icon from "../components/Icon";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import { baseUrl } from "../helpers/helpers";

const PasswordForgot = ({ setLanguage }) => {
  const [success, setSuccess] = useState(false);

  const [email, setEmail] = useState("");

  const { t } = useTranslation();

  const requestPasswordReset = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${baseUrl}/reset-password`,
    });

    if (error) {
      console.log(error);
    } else {
      setSuccess(true);
    }
  };

  return (
    <>
      <header className="login">
        <div className="w-full flex items-center justify-center pt-12 relative z-[1000000]">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/">
              <Icon
                icon="heroicons:arrow-left"
                className="w-12 h-12 text-white"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>

        <div className="login_form max-lg:!items-start max-lg:mt-12">
          <form
            className="form w-full"
            name="f1"
            onSubmit={requestPasswordReset}
            method="post"
          >
            <div className="lg">
              <div className="">
                <p className="flex protected">
                  <span className="ml-1.5 text-[24px]">
                    {t("enter_your_email")}
                  </span>
                </p>
                <div>
                  <input
                    type="email"
                    name="name"
                    className={
                      "!py-[15px] outline-none !text-white !border-none !bg-[#130826]"
                    }
                    id="validationCustom01"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div>
                    {success && (
                      <div id="namelocation" className="flex items-center !font-semibold !text-green-500">
                        <span className="ml-1">
                          We have sent you a link with instructions to reset your
                          password.
                        </span>
                      </div>
                    )}
                  <Button
                    type="submit"
                    className="text-[16px] font-semibold mt-5 btn bg-gradient-to-r shadow-md to-[#7721E5] from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
                  >
                    {t("continue")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </header>
    </>
  );
};

export default PasswordForgot;
