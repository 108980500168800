import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../pages/home/Home";
import { supabase } from "../supabaseClient";
import Icon from "../components/Icon";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";

const PasswordReset = ({ setLanguage }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const navigate = useNavigate();

  const requestPasswordReset = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: password,
    });

    if (error) {
      console.log(error);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <header className="login">
        <div className="w-full flex items-center justify-center pt-12 relative z-[1000000]">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/">
              <Icon
                icon="heroicons:arrow-left"
                className="w-12 h-12 text-white"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>

        <div className="login_form max-lg:!items-start max-lg:mt-12">
          <form
            className="form w-full"
            name="f1"
            onSubmit={requestPasswordReset}
            method="post"
          >
            <div className="lg">
              <div className="">
                <p className="flex protected">
                  <span className="ml-1.5 text-[24px]">
                    Enter a new password
                  </span>
                </p>
                <div>
                  <input
                    type="password"
                    name="name"
                    className={
                      "!py-[15px] outline-none !text-white !border-none !bg-[#130826]"
                    }
                    id="validationCustom01"
                    placeholder="New password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="name"
                    className={
                      "mt-3 !py-[15px] outline-none !text-white !border-none !bg-[#130826]"
                    }
                    id="validationCustom01"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>

                <div>
                  {error && (
                    <div
                      id="namelocation"
                      className="flex items-center !font-semibold !text-red-500"
                    >
                      <span className="ml-1">
                        {error}
                      </span>
                    </div>
                  )}
                  <Button
                    type="submit"
                    className="text-[16px] font-semibold mt-5 btn bg-gradient-to-r shadow-md to-[#7721E5] from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
                  >
                    {t("continue")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </header>
    </>
  );
};

export default PasswordReset;
