import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import Icon from "../components/Icon";
import CreateCityModal from "../components/CreateCityModal";

export default function ManageCities() {
  const [cities, setCities] = useState([]);
  const [showAddCityModal, setShowAddCityModal] = useState(false);
  const [citySignups, setCitySignups] = useState();

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data);
      });
  }, []);

  useEffect(() => {
    async function getSignups() {
      const signupsPerCity = {};
      for (const city of cities) {
        const signups = await supabase
          .from("EventSignUp")
          .select("id, event:Events (*)")
          .gte("created_at", dayjs().subtract(7, "day").toISOString())
          .eq("event.city", city.name);
        signupsPerCity[city.name] =
          signups.data.filter((signup) => signup.event).length || 0;
        // signupsPerCity[city.name] = signups;
      }
      setCitySignups(signupsPerCity);
    }
    getSignups();
  }, [cities]);

  const handleDelete = (id) => {
    supabase
      .from("City")
      .delete()
      .eq("id", id)
      .then((res) => {
        setCities(cities.filter((city) => city.id !== id));
      });
  };

  return (
    <div className="w-full text-white bg-black text-3xl mt-12 overflow-x-auto p-5">
      <CreateCityModal
        show={showAddCityModal}
        setShow={setShowAddCityModal}
        setCities={setCities}
      />
      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Cities
        </h1>
        <button
          className="bg-white text-black p-4 rounded-md flex items-center gap-2"
          onClick={() => setShowAddCityModal(true)}
        >
          <Icon icon="heroicons:plus" className="w-10 h-10" />
          Add city
        </button>
      </div>
      <Table className="mt-8">
        <Table.Head>
          <Table.HeadCell className="text-3xl capitalize w-[80%]">
            City
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[80%]">
            Signups this week
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[10%]">
            Actions
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {cities?.map((city, index) => {
            return (
              <Table.Row
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}
              >
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {city.name}
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {city.name in citySignups
                    ? citySignups[city.name]
                    : "Loading"}
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-red-500">
                  <button onClick={() => handleDelete(city.id)}>
                    <Icon icon="heroicons:trash" className="w-10 h-10" />
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
