import React from "react";
export default function ProfileGenresForm({
  availableGenres,
  formData,
  addOrRemoveGenre,
}) {
  return (
    <div className="max-lg:mt-[16px]">
      <h3 className="text-[18px] text-white">Favoriete genres</h3>
      <div className="mt-1">
        {availableGenres.map((genre, index) => {
          return (
            <div
              className={`flex items-center justify-start gap-4 !p-[10px] outline-none !text-neutral-200 !border border-gray-600 w-full !bg-transparent ${
                index % 2 > 0 ? "border-t-none" : "border-b-none"
              } ${
                index === 0
                  ? "border-t-[4px]"
                  : index === availableGenres.length - 1 && "border-b-[4px]"
              }`}
            >
              <input
                type="checkbox"
                className="!m-0 !p-0 h-7 w-7 bg-transparent rounded-[4px]"
                checked={formData.genres?.includes(genre)}
                onChange={() => {
                  addOrRemoveGenre(genre);
                }}
              />
              <h4 className="!text-[16px]">{genre}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
}
