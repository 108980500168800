import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { Link } from "react-router-dom";
import WhatsappLogo from "../assets/whatsapp-logo.svg";
import Logo from "../components/Logo";
import { CustomSelect } from "./home/Home";
import Icon from "../components/Icon";
import ProfileSidebar from "../components/ProfileSidebar";

export default function Profile({ language, setLanguage }) {
  const user = useSelector(selectUser);

  return (
    <div className="bg-[#0a0215]">
      <div
        className="hero-section relative min-h-[100vh] "
        style={{ background: "#0a0215" }}
      >
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="https://linktr.ee/22nightmember" target="_blank">
              <img
                src={WhatsappLogo}
                className="w-12 h-12"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="lg:w-[80%] max-lg:w-full">
            <div className="flex flex-col items-start text-start max-lg:hidden pt-[60px]">
              <h1 className="text-start text-[50px]">
                Hallo {user.first_name}
              </h1>
              <hr className="border-[#302640] mt-[20px] w-full " />
            </div>
            <div className="lg:max-w-[20%]">
              <ProfileSidebar user={user} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
